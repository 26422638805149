import { api } from "../../shared/services/api";
import {
  CreateApprovalRuleRequest,
  GetApprovalRuleResponse,
  EditApprovalRuleRequest,
  CreateApprovalRequestBody,
} from "../dtos/approval-process.dto";
import {
  SendApprovalResponseRequestBody,
  GetTravelApprovalStatusResponse,
} from "../dtos/approval-status.dto";
import { GetApprovalsListResponse } from "../dtos/approvals.dto";
import { getAuthorizationHeader, getClientToken } from "../helpers/user.helper";
import {
  ApprovalProcessListItem,
  ApprovalProcessSearchItem,
  ApprovalTarget,
} from "../models/approval-process.model";
import { UserSearch } from "../models/user.model";
import * as approvalProcessParser from "../parsers/approval-process.parser";
import { UserAutocompleteFactory } from "../parsers/user.parser";

export async function approveApprovalRequest(
  approvalRequestToken: string,
  data: SendApprovalResponseRequestBody,
) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/approval-requests/${approvalRequestToken}/approve`,
  });
}

export async function cancelApprovalProcess(approvalRequestToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/approval/cancel/${approvalRequestToken}`,
  });
}

export async function createApprovalRule(data: CreateApprovalRuleRequest) {
  return await api
    .request<{ approval_process_token: string }>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/approvals`,
    })
    .then(({ data }) => ({
      approvalRuleToken: data.approval_process_token,
    }));
}

export async function createApprovalRequest(
  travelToken: string,
  data: CreateApprovalRequestBody,
) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/request-approval`,
    })
    .then(({ data }) => data);
}

export async function deleteApprovalRule(approvalToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/approvals/${approvalToken}/inactivate`,
  });
}

export async function denyApprovalRequest(
  approvalRequestToken: string,
  data: SendApprovalResponseRequestBody,
) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "POST",
    url: `/booking/approval-requests/${approvalRequestToken}/deny`,
  });
}

export async function denyOfferApproval(offerToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/offers/${offerToken}/deny`,
  });
}

export async function editApprovalRule(
  approvalToken: string,
  data: EditApprovalRuleRequest,
) {
  return await api
    .request<{ approval_process_token: string }>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/clients/${getClientToken()}/approvals/${approvalToken}`,
    })
    .then(({ data }) => ({
      approvalRuleToken: data.approval_process_token,
    }));
}

export async function getApprovalRule(approvalToken: string) {
  return await api
    .request<GetApprovalRuleResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/approvals-flows/${approvalToken}`,
    })
    .then(({ data }) => data);
}

export async function getApprovalRulesList() {
  return await api
    .request<ApprovalProcessListItem[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${getClientToken()}/approvals`,
    })
    .then(({ data }) => data);
}

export async function getApprovalsList(
  page: number,
  status: string,
  search?: string,
) {
  return await api
    .request<GetApprovalsListResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: {
        search: search ? search : "",
      },
      url: `/booking/travels/approvals/${page}/${status}`,
    })
    .then(({ data }) => {
      const { current, pages, total_travels, travels } = data;

      return {
        currentPage: parseInt(current, 10),
        pages,
        totalTravels: total_travels,
        travels: travels.map((travel) => travel),
      };
    });
}

export async function getPendingApprovalTravelTokensByUser() {
  return await api
    .request<string[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: "/booking/travels/user/pending-approval",
    })
    .then(({ data }) => data);
}

export async function getTravelApprovalStatus(travelToken: string) {
  return await api
    .request<GetTravelApprovalStatusResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/travels/${travelToken}/approval-status`,
    })
    .then(({ data }) => approvalProcessParser.parseTravelApprovalStatus(data));
}

export async function saveApprovalTarget(data: {
  targetToken: string;
  approvalToken: string;
}) {
  return await api
    .request<ApprovalTarget[]>({
      data: {
        approval_token: data.approvalToken,
        target_token: data.targetToken,
      },
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/approval-targets`,
    })
    .then(({ data }) => data);
}

export async function searchApprovalProcesses(data: {
  searchQuery?: string;
  targetType?: string;
  userToken?: string;
}) {
  return await api
    .request<ApprovalProcessSearchItem[]>({
      data: {
        search_query: data.searchQuery,
        target_type: data.targetType,
        user_token: data.userToken,
      },
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/approvals/search`,
    })
    .then(({ data }) => data);
}

export async function searchApprovalTarget(
  searchString: string,
  targetType?: string,
) {
  return await api
    .request<ApprovalTarget[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params: {
        text: searchString,
        type: targetType,
      },
      url: `/booking/clients/${getClientToken()}/approval-targets`,
    })
    .then(({ data }) => data);
}

export async function searchApproverUsersByNameAndEmail(value: string) {
  return await api
    .request<UserSearch[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/user/approver/search/${value}`,
    })
    .then(({ data }) =>
      data.map((userDto) => UserAutocompleteFactory(userDto)),
    );
}

export async function undenyOfferApproval(offerToken: string) {
  return await api.request<any>({
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/offers/${offerToken}/undeny`,
  });
}
