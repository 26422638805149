import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";

import { api } from "../../shared/services/api";
import {
  ClientBillingProfileDto,
  CreateBillingProfileRequestBody,
  CreateBillingProfileResponse,
  GetClientBillingProfileListResponse,
  GetUserBillingProfilesResponse,
  GetBillingProfilePaymentOptionsForTravelResponse,
  GetBillingProfilePayableCardsForTravelResponse,
  ListBookerPayableCardsResponse,
} from "../dtos/billing-profile.dto";
import * as billingProfileParser from "../parsers/billing-profile.parser";

export async function createBillingProfile(
  data: CreateBillingProfileRequestBody,
) {
  return await api
    .request<CreateBillingProfileResponse>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/billing-profiles`,
    })
    .then(({ data }) => data.billing_profile_token);
}

export async function deleteBillingProfile(token: string) {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/billing-profiles/${token}`,
    })
    .then(({ data }) => data);
}

export async function getBillingProfilePayableCardsForTravel(
  billingProfileToken: string,
  travelToken: string,
) {
  return await api
    .request<GetBillingProfilePayableCardsForTravelResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/billing-profiles/${billingProfileToken}/travels/${travelToken}/cards-new`,
    })
    .then(({ data }) =>
      billingProfileParser.parseBillingProfilePayableCardsForTravel(data),
    );
}

export async function listBookerPayableCards(travelToken: string) {
  return await api
    .request<ListBookerPayableCardsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/travels/${travelToken}/booker-cards`,
    })
    .then(({ data }) =>
      billingProfileParser.parseBillingProfilePayableCardsForTravel(data),
    );
}

export async function getBillingProfilePaymentOptionsForTravel(
  billingProfileToken: string,
  travelToken: string,
) {
  return await api
    .request<GetBillingProfilePaymentOptionsForTravelResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/billing-profiles/${billingProfileToken}/travels/${travelToken}/pay-options`,
    })
    .then(({ data }) => data);
}

export async function getClientBillingProfiles(clientToken: string) {
  return await api
    .request<GetClientBillingProfileListResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${clientToken}/billing-profiles`,
    })
    .then(({ data }) =>
      data.map((item) => billingProfileParser.parseClientBillingProfile(item)),
    );
}

export async function getSingleBillingProfile(token: string) {
  return await api
    .request<ClientBillingProfileDto>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/billing-profiles/${token}`,
    })
    .then(({ data }) => billingProfileParser.parseClientBillingProfile(data));
}

export async function getUserBillingProfiles(userToken: string) {
  return await api
    .request<GetUserBillingProfilesResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/billing-profiles`,
    })
    .then(({ data }) => billingProfileParser.parseUserBillingProfiles(data));
}

export async function updateBillingProfile(
  data: CreateBillingProfileRequestBody,
  token: string,
) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/billing-profiles/${token}`,
    })
    .then(({ data }) => data);
}
